export default {
  data() {
    const searchQuery = ''

    return {
      searchQuery,
    }
  },
  methods: {
    resetSearch() {
      this.searchQuery = ''
      this.getDataFromApi()
    },
  },
}
